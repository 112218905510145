import { httpClient } from '../../utilities/httpClient.js';
const opportunitiesStore = {
    state() {
        return {
            opportunities: [],
            currentOpportunity: {},
            currentOpportunityDocs: [],
            opportunitiesHaveLoaded: false,
            opportunitiesLastLoaded:0,
            currentBidTemplate: []
        }
    },
    getters: {
        getOpportunitiesList(state) {return state.opportunities},
        getCurrOpportunity(state) {return state.currentOpportunity},
        getCurrOpportunityDocs(state) {return state.currentOpportunityDocs},
        getOpportunitiesHaveLoaded(state) {return state.opportunitiesHaveLoaded},
        getOpportunitiesLastLoaded(state) {return state.opportunitiesLastLoaded},
        getCurrentBidTemplate(state) {return state.currentBidTemplate},
    },
    actions: {
        getAllOpportunities(context){
            // IF NOT ENOUGH TIME HAS PASSED, RELOAD FROM STORE
            /*if(parseInt(new Date().getTime()) - parseInt(context.getters.getUsersLastLoaded) < 5000){
                return;
            }*/
            // ELSE RELOAD FROM SERVER
            return new Promise((resolve, reject) => {
                context.commit('setOpportunitiesHaveLoaded', false);
                context.commit('setLoadingOverlayOn', true);
                httpClient.get(context.rootState.BASE_URL + '/user/opportunities?lower_date=2020-01-01&state=active', { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then((response) => {
                    console.log(response);
                    context.commit('setOpportunitiesHaveLoaded', true);
                    context.commit('setLoadingOverlayOn', false);
                    context.commit('setOpportunitiesLastLoaded', new Date().getTime());
                    context.commit('setOpportunitiesList', response.data.data);
                    resolve();
                })
                .catch((error) => {
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    context.commit('setLoadingOverlayOn', false);
                    reject();
                }); 
            });// PROMISE
        },
        getSingleOpportunity(context, id){
            // IF NOT ENOUGH TIME HAS PASSED, RELOAD FROM STORE
            /*if(parseInt(new Date().getTime()) - parseInt(context.getters.getUsersLastLoaded) < 5000){
                return;
            }*/
            // ELSE RELOAD FROM SERVER
            return new Promise((resolve, reject) => {
                httpClient.get(context.rootState.BASE_URL + '/user/opportunities?opportunity_id=' + id, { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then((response) => {
                    console.log(response);
                    resolve(response.data.data);
                })
                .catch((error) => {
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    reject();
                }); 
            });// PROMISE
        },
        getOpportunityDocs(context, id){
            return new Promise((resolve, reject) => {
                // context.commit('setLoadingOverlayOn', true);
                httpClient.get(context.rootState.BASE_URL + '/user/documents?opportunity_id=' + id, { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then((response) => {
                    console.log(response);
                    // context.commit('setLoadingOverlayOn', false);
                    context.commit('setCurrOpportunityDocs', response.data.data);
                    resolve(response.data.data);
                })
                .catch((error) => {
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    // context.commit('setLoadingOverlayOn', false);
                    reject();
                }); 
            });// PROMISE
        },
        getOpportunityBidTemplate(context, id){
            return new Promise((resolve, reject) => {
                // context.commit('setLoadingOverlayOn', true);
                httpClient.get(context.rootState.BASE_URL + '/user/opportunity/forms?form_template_id=' + id, { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then((response) => {
                    console.log("BID FORM TEMPLATE:");
                    console.log(response.data.data[0].template_body);
                    context.commit('setCurrentBidTemplate', response.data.data[0].template_body);
                    resolve();
                })
                .catch((error) => {
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    // context.commit('setLoadingOverlayOn', false);
                    reject();
                }); 
            });// PROMISE
        }
    },
    mutations: {
        setOpportunitiesList(state, opportunities) { state.opportunities = opportunities },
        setCurrOpportunity(state, currentOpportunity) {state.currentOpportunity = currentOpportunity},
        setCurrOpportunityDocs(state, currentOpportunityDocs) {state.currentOpportunityDocs = currentOpportunityDocs},
        setOpportunitiesHaveLoaded(state, opportunitiesHaveLoaded) {state.opportunitiesHaveLoaded = opportunitiesHaveLoaded},
        setOpportunitiesLastLoaded(state, opportunitiesLastLoaded) {state.opportunitiesLastLoaded = opportunitiesLastLoaded},
        setCurrentBidTemplate(state, currentBidTemplate) { state.currentBidTemplate = currentBidTemplate},
    }
};

export default opportunitiesStore;
