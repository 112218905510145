import { httpClient } from '../../utilities/httpClient.js';
const usersStore = {
    state() {
        return {
            currentUser: {},
        }
    },
    getters: {
        getCurrentUser(state) { return state.currentUser},
    },
    actions: {
        createUser(context, payload){
            return new Promise((resolve, reject) => {
                context.commit('setLoadingOverlayOn', true);
                httpClient.post(context.rootState.BASE_URL + '/user/web', 
                // Payload
                /* 
                {
                    name: string,
                    last_name: string,
                    email: string,
                    password: string,
                    phone: string,
                    company_name: string,
                    file: file, // Profile photo
                    file_name: string, // profile photo
                    creation_platform: 'web' // inject before sending,
                    address: { // Make sure to encode before adding to JSON
                        line_1: string,
                        line_2: string,
                        city: string,
                        state: string,
                        zip_code: string,
                    }
                    preferences: { // Make sure to encode before adding to JSON
                        naics: [string], // if old user only
                        usi_codes: ["string"],
                        notification: {
                            within: string,
                            outside: string
                        },
                        registration_type: string,
                        seb_certification: string,
                    }
                    detailed_description_of_services: 'string',
                    state: string,
                    registration_push_token: string // can't do it from admin, user must subscribe
                }
                 */
                payload,
                { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then((response) => {
                    console.log('User registered: ');
                    console.log(response);
                    console.log(this.$gtag)
                    /* this.$gtag.event("vendor_registered", {
                    }) */
                    alert('User created!');
                    console.log(response);
                    context.commit('setLoadingOverlayOn', false);
                    resolve(response.data.data.user_id);
                })
                .catch((error) => {
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    context.commit('setLoadingOverlayOn', false);
                    reject();
                }); 
            });// PROMISE
        },
        editUser(context, payload){
            return new Promise((resolve, reject) => {
                context.commit('setPagesHaveLoaded', false);
                // context.commit('setLoadingOverlayOn', true);
                httpClient.put(context.rootState.BASE_URL + '/user/' + payload.user_id, 
                payload.data,
                { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then(async (response) => {
                    console.log('User edited!');
                    console.log(response);
                    // UPDATE USER DATA!!!!
                    await context.dispatch('getUserData');
                    resolve();
                })
                .catch((error) => {
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    reject();
                }); 
            });// PROMISE
        },
        async updateCurrentUserDownloads(context) { 
            return new Promise((resolve, reject) => {
                // DISPATCH USER EDIT
                var formData = new FormData();
                formData.append('has_downloaded', JSON.stringify(context.getters.getCurrentUser.has_downloaded));
                context.dispatch('editUser', {user_id: context.getters.getUser, data: formData })
                .then(()=>{
                    resolve();
                })
                .catch((e)=>{
                    alert('ERROR UPDATING USER DOWNLOADS');
                    console.log(e);
                    reject();
                });
            });// PROMISE
        },
        async recoverPassword(context, payload) { 
            return new Promise((resolve, reject) => {
                httpClient.post(context.rootState.BASE_URL + '/user/password', 
                {"email": payload.email},
                { 
                    headers: {
                        // Send saved login token with code verification
                        //Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then(async (response) => {
                    console.log('Password requested!');
                    console.log(response);
                    /*this.$gtag.event("request_password", {
                      "seb_user_email" : "&&& the email the user entered into the recover password field OR the email in the user record",
                      "seb_user_id" : "&&& the db id of the user who is trying to recover his password"
                    })*/
                    resolve();
                })
                .catch((error) => {
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    reject();
                });
            });// PROMISE
        },
    },
    mutations: {
        setCurrentUser(state, currentUser) { state.currentUser = currentUser },
    }
};

export default usersStore;
