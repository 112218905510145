import { httpClient } from '../../utilities/httpClient.js';
const opportunitiesStore = {
    state() {
        return {
            currentBid: {},
        }
    },
    getters: {
        getCurrentBid(state) { return state.currentBid }
    },
    mutations: {
        setCurrentBid(state, currentBid) { state.currentBid = currentBid }
    },
    actions: {
        getSingleBid(context, id){
            return new Promise((resolve, reject) => {
                httpClient.get(context.rootState.BASE_URL + '/user/bids?opportunity_id=' + id, { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then((response) => {
                    console.log(response);
                    context.commit('setCurrentBid', {id: response.data.data.bid_id});
                    resolve(response.data.data);
                })
                .catch((error) => {
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    reject();
                }); 
            });// PROMISE
        },
        createBid(context, payload){
            /* PAYLOAD
            {
            "fields": [
                {
                "field": "string",
                "value": "string"
                }
            ],
            "opportunity_id": "string"
            }
            */
            return new Promise((resolve, reject) => {
                httpClient.post(context.rootState.BASE_URL + '/user/bid', 
                payload,
                { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then((response) => {
                    // GET ID
                    console.log('CREATED BID:');
                    console.log(response);
                    context.commit('setCurrentBid', {id: response.data.data.bid_id});
                    resolve(response.data.data);
                })
                .catch((error) => {
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    reject();
                }); 
            });// PROMISE
        },
        editBid(context, payload){
            return new Promise((resolve, reject) => {
                httpClient.put(context.rootState.BASE_URL + '/user/bids/' + payload.id, 
                payload.data,
                { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then((response) => {
                    console.log(response);
                    resolve(response.data.data);
                })
                .catch((error) => {
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    reject();
                }); 
            });// PROMISE
        },
        addBidDoc(context, payload){
            /* PAYLOAD
            {
                bid_id,
                file,
                file_name
            }
            */
            return new Promise((resolve, reject) => {
                httpClient.post(context.rootState.BASE_URL + '/user/document', 
                payload,
                { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then((response) => {
                    // GET ID
                    console.log('CREATED BID DOCUMENT:');
                    console.log(response);
                    // context.commit('setCurrentBid', {id: response.data.data.bid_id});
                    resolve(response.data.data);
                })
                .catch((error) => {
                    // A DOCUMENT FAILED TO UPLOAD, HANDLE ERROR APPROPRIATELY
                        // What document failed?
                        // Ask user to retry? or retry automatically? (track attempts)
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    reject();
                }); 
            });// PROMISE
        },
        getBidDocs(context, id){
            return new Promise((resolve, reject) => {
                // context.commit('setLoadingOverlayOn', true);
                httpClient.get(context.rootState.BASE_URL + '/user/documents?bid_id=' + id, { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then((response) => {
                    console.log('GOT BID DOCS');
                    console.log(response);
                    // context.commit('setLoadingOverlayOn', false);
                    // context.commit('setCurrBidDocs', response.data.data);
                    resolve(response.data.data);
                })
                .catch((error) => {
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    // context.commit('setLoadingOverlayOn', false);
                    reject();
                }); 
            });// PROMISE
        },
    },
};

export default opportunitiesStore;
