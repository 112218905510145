import { createRouter, createWebHistory } from 'vue-router';
import store from '../store/index.js';
import Home from '../views/Home.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/opportunities',
    name: 'Opportunities',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import( webpackChunkName: "about"  '../views/About.vue')
    component: () => import('../views/Opportunities.vue')
  },
  {
    path: '/single-opportunity/:id',
    name: 'SingleOpportunity',
    component: () => import('../views/SingleOpportunity.vue')
  },
  {
    path: '/registration',
    name: 'Register',
    component: () => import('../views/Register.vue')
  },
  {
    path: '/announcements',
    name: 'Announcements',
    component: () => import('../views/Announcements.vue')
  },
  {
    path: '/single-announcement/:id',
    name: 'SingleAnnouncement',
    component: () => import('../views/SingleAnnouncement.vue')
  },
  {
    path: '/pages/:slug',
    name: 'SinglePage',
    component: () => import('../views/SinglePage.vue')
  },
  {
    path: '/register',
    name: 'NewUser',
    component: () => import('../views/NewUser.vue')
  },
  {
    path: '/profile',
    name: 'EditUser',
    component: () => import('../views/EditUser.vue')
  },
  {
    path: '/recover-password',
    name: 'PasswordRecovery',
    component: () => import('../views/PasswordRecovery.vue')
  },
  {
    path: '/delete-account',
    name: 'DeleteAccount',
    component: () => import('../views/DeleteAccount.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // history: process.env.IS_ELECTRON ? createWebHashHistory() : createWebHistory(),
});

const waitForStorageToBeReady = async (to, from, next) => {
  await store.restored;
  next();
}
router.beforeEach(waitForStorageToBeReady);

router.beforeEach(async function (to, from, next) {
  // console.log(to);
  // console.log(from);
  // SHOW SPINNER WHILE CHECK HAPPENS
  store.commit('setNavInProgress', true);

  // Get query params
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  let keys = urlParams.keys();
  let queryParams = {};
  for (const key of keys) {
    queryParams[key] = urlParams.get(key);
  }

  var checkResult = store.getters.getIsLoggedIn;

  if (to.fullPath.includes('/delete-account')) {
    store.state.isAccDel = true;
  }

  // console.log('checkResult: ' + checkResult);
  if (to.fullPath === '/' || to.fullPath === '/login' || to.fullPath === '/register' || to.fullPath === '/recover-password') {

    // GOING TO LOGIN/HOME
    if (!store.getters.getIsLoggedIn) {
      next();
    } else {
      // next('/users');
      // NEED CHECK INCOMING URL FOR REDIRECTION AFTER LOGIN - EXTERNAL LINKING
      if (store.state.isAccDel) {
        next('/delete-account');
      } else {
        next('/opportunities');
      }
    }

  } else {
    // GOING TO ANOTHER ROUTE
    if (checkResult) {
      // CHECK PATH
      // if /opportunities or /single-opportunity, make sebNavCurrent 'opportunities'
      if (to.fullPath.includes('/opportunities') || to.fullPath.includes('/single-opportunity')) {
        store.commit('setSebNavCurrent', 'opportunities');
      }
      // if /announcements or /single-announcement, make sebNavCurrent 'announcements'
      if (to.fullPath.includes('/announcements') || to.fullPath.includes('/single-announcement')) {
        store.commit('setSebNavCurrent', 'announcements');
      }
      // if /profile, make sebNavCurrent 'profile'
      if (to.fullPath.includes('/profile')) {
        store.commit('setSebNavCurrent', 'profile');
      }
      // if /register, make sebNavCurrent 'register'
      if (to.fullPath.includes('/register')) {
        store.commit('setSebNavCurrent', 'register');
      }
      // if /delete-account, make sebNavCurrent 'delete-account'
      if (to.fullPath.includes('/delete-account')) {
        store.commit('setSebNavCurrent', 'delete-account');
      }
      // if / or /login, make sebNavCurrent 'login'
      if (to.fullPath == '/' || to.fullPath.includes('/login')) {
        store.commit('setSebNavCurrent', 'login');
      }
      store.dispatch('getPages');
      store.dispatch('getOpportunityBidTemplate', '2021_05_28_dummy_form');
      // ALLOW NAVIGATION
      next();
    } else {
      // BACK TO LOGIN
      console.log('DENIED PASSAGE - START OVER');
      next('/');
    }
  }
  // HIDE SPINNER
  store.commit('setNavInProgress', false);
});


export default router
