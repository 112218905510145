<template>
  <div class="home">
    <div id="seb-login-form">
      <h2 v-if="$store.state.isAccDel" id="acc-del">To delete your account, first log in.</h2>
      <h1 v-else id="seb-login-title">WELCOME</h1>
      <ul>
        <li class="seb-form-item">
          <input type="email" placeholder="Username" v-model="username"/>
        </li>
        <li class="seb-form-item">
          <input type="password" placeholder="Password" v-model="password" />
        </li>
        <li id="seb-login-submit" class="seb-form-item">
          <router-link id="seb-forgot-password" to="/recover-password">Forgot your password?</router-link><button class="seb-button gold" @click="sendLogin">LOG IN<img class="button-icon button-icon-right" src="@/assets/icons/arrow-white-right.svg"/></button>
        </li>
        <li class="seb-login-register-link">
          <span>Don't have an account yet? </span><router-link to="/register">Create one here.</router-link>
        </li>
      </ul>

      <img id="seb-welcome-logo" src="@/assets/pelican_logo.png"/>

      <div class="badges">
        <div class="apple-badge">
          <a href='https://apps.apple.com/us/app/sebconnect/id1468985489' target="_blank">
          <img class="apple-badge" alt='Download on the App Store' src='@/assets/app_store_badge.png'/></a>
        </div>
        <div class="google-badge">
          <a href='https://play.google.com/store/apps/details?id=com.seb.mcc&hl=en_US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
          <img class="google-badge" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
        </div>
      </div>
    </div>

    <p v-if="false">{{username}} : {{password}}</p>

    <div v-if="false">
      <p>Token: {{ $store.getters.getAuthToken }}</p>
      <p>Ttl: {{ $store.getters.getAuthTokenTtl }}</p>
      <p>User: {{ $store.getters.getUser }}</p>
      <p>Last login: {{ $store.getters.getLastAuth }}</p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  data(){
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    sendLogin(){
      // SANITIZE AND PROTECT PASSWORD FIELD
      // THEN SUBMIT
      this.$store.dispatch('sendUserLogin', {username: this.username, password: this.password})
      .then(async (res)=>{
        if(res){
          this.password = '';
          // CHECK IF ACCOUNT DELETION
          if(this.$store.state.isAccDel){
            this.$router.push('/delete-account');
          } else {
            this.$router.push('/opportunities');
          }
        } else {
          console.log('No Login')
        }
        
      });
    }
  }
}
</script>

<style scoped>
#seb-login-form {
  width:300px;
  margin-top:calc(50vh - 300px);
  margin-left: calc(50% - 150px);
}
#seb-welcome-logo {
  width:150px;
  height:150px;
  display:block;
  padding:70px 0 70px;
  margin-left:calc(50% - 75px);
}
#seb-login-title {
  color:#DAB36F;
  font-size:18px;
  letter-spacing:3px;
  font-weight:700;
  margin:0;
  padding-bottom: 25px;
  padding-left:8px;
}
#seb-login-form ul {
  padding:0;
  margin:0;
  list-style:none;
}
#seb-login-form ul li{
  margin: 0 0 4px 0;
}
#seb-login-form ul li input{
  border-color:#DAB36F;
  font-weight:700;
}
#seb-forgot-password {
  color:#2CB0F4;
  font-size:13px;
  font-weight:600;
  padding-left: 10px;
  margin-right:40px;
}
#seb-login-form ul li input::placeholder {
  color: #103966;
  opacity:1;
}
#seb-login-form ul li input:focus::placeholder {
  opacity:0.33
}
.seb-login-register-link {
  padding: 25px 0 0;
}
.seb-login-register-link span {
  font-size:14px;
  font-weight:700;
}
.seb-login-register-link a {
  font-size:14px;
  font-weight:700;
  color:#2CB0F4;
  cursor: pointer;
}
.seb-login-register-link a:hover {
  color:#DAB36F;
  text-decoration:underline;
}
#acc-del {
  font-size:16px;
  color:#fff;
  line-height:20px;
  padding:8px 10px;
  border-radius: 6px;
  background-color:rgba(255,0,0,0.66);
  font-weight:400;
}
@media all and (min-width: 769px) {
  #seb-login-form {
    width:300px;
    margin-top:calc(50vh - 140px);
    margin-left: 50px;
  }
  #seb-welcome-logo {
    display:none;
  }
}

</style>
