import { httpClient } from '../../utilities/httpClient.js';
import router from '../../router/index.js';

const authStore = {
    // ONLY MANAGE AUTH-RELATED ITEMS HERE
    state() {
        return {
            isLoggedIn: false,
            lastAuth: '0', // timestamp
            authToken: 'none', // session token
            authTokenTtl: 0,
            user: 'none',
            isAccDel: false,
        }
    },
    getters: {
        getIsLoggedIn(state) { return state.isLoggedIn },
        getLastAuth(state) { return state.lastAuth },
        getAuthToken(state) { return state.authToken },
        getAuthTokenTtl(state) { return state.authTokenTtl },
        getUser(state) { return state.user },
    },
    actions: {

        // POST USER LOGIN WITH USERNAME AND PASSWORD
        sendUserLogin(context, payload) {
            context.commit('setLoadingOverlayOn', true);
            return new Promise((resolve) => {
                httpClient.post(context.rootState.BASE_URL + '/user/login', {
                    'email': payload.username,
                    'password': payload.password
                }).then(async (response) => {

                    if (response.data.data.user_id) {
                        // IF SUCCESS
                        console.log('SUCCESS with login credentials');
                        console.log(response);
                        // Save login token for use in code verification
                        context.commit('setAuthToken', response.data.data.token);
                        context.commit('setUser', response.data.data.user_id);
                        context.commit('setAuthTokenTtl', response.data.data.tl_token);
                        context.commit('setIsLoggedIn', true);
                        context.commit('setLastAuth', new Date().getTime());
                        // GET USER DATA
                        await context.dispatch('getUserData');
                        // Make sure to turn false in once the users page is loaded
                        context.commit('setLoadingOverlayOn', false);
                        resolve(true);
                    } else {
                        // ERROR
                        throw 401
                    }
                })
                    .catch((error) => {
                        console.log('ERROR: ', error);
                        alert('Error: ' + 'invalid user or password');
                        context.commit('setLoadingOverlayOn', false);
                        resolve(false);
                    });
            });//PROMISE
        },
        getUserData(context) {
            return new Promise((resolve) => {
                httpClient.get(context.rootState.BASE_URL + '/user', {
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                    .then((response) => {
                        console.log('SUCCESS GETTING USER DATA');
                        console.log(response);
                        if (response.data.data.id == context.getters.getUser) {
                            // IF SUCCESS
                            console.log('CONFIRMED');
                            if (!response.data.data.has_downloaded) {
                                response.data.data.has_downloaded = [
                                    { downloaded: [], opportunity_id: '' }
                                ];
                            }
                            context.commit('setCurrentUser', response.data.data);
                            resolve();
                        } else {
                            // ERROR
                            throw 401
                        }
                    })
                    .catch(() => {
                        alert('Error: undefined');
                        context.commit('setLoadingOverlayOn', false);
                        resolve();
                    });
            });//PROMISE
        },
        // HANDLE END OF SESSION 
        handleSessionEnd(context) {
            // Invalidate token, chnage auth state in store, go to login view
            httpClient.get(context.rootState.BASE_URL + '/user/logout',
                {
                    headers: {
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`,
                    }
                })
                .then(() => {
                    // NAVIGATE TO HOME VIEW
                    context.state.isAccDel = false;
                    context.commit('setIsLoggedIn', false);
                    context.commit('setAuthToken', 'none');
                    context.commit('setAuthTokenTtl', 0);
                    context.commit('setUser', 'none');
                    localStorage.removeItem('vuex');
                    router.push({ path: '/', replace: true });
                })
                .catch((/*error*/) => {
                    console.log('invalid TOKEN');
                    context.state.isAccDel = false;
                    // NAVIGATE TO HOME VIEW ALSO
                    context.commit('setIsLoggedIn', false);
                    context.commit('setAuthToken', 'none');
                    context.commit('setAuthTokenTtl', 0);
                    context.commit('setUser', 'none');
                    router.push({ path: '/', replace: true });

                });
        },
        accountDeletion(context, payload) {
            context.commit('setLoadingOverlayOn', true);
            return new Promise((resolve) => {
                httpClient.delete(context.rootState.BASE_URL + '/user/' + payload.user_id, {
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                    .then((response) => {
                        console.log('SUCCESS REQUESTING ACCOUNT DELETION');
                        console.log(response.data.data);
                        context.state.isAccDel = false;
                        context.commit('setLoadingOverlayOn', false);
                        resolve(true);
                    })
                    .catch(() => {
                        alert('Error: undefined');
                        context.commit('setLoadingOverlayOn', false);
                        resolve(false);
                    });
            });//PROMISE
        }
    },
    mutations: {
        setIsLoggedIn(state, isLoggedIn) { state.isLoggedIn = isLoggedIn },
        setLastAuth(state, lastAuth) { state.lastAuth = lastAuth },
        setAuthToken(state, authToken) { state.authToken = authToken },
        setAuthTokenTtl(state, authTokenTtl) { state.authTokenTtl = authTokenTtl },
        setUser(state, user) { state.user = user },
    },
};

export default authStore;

// PENDIENTES
// 1. Recuperación de contraseña? o por ahora manual
// 2. Cambios de credenciales? o por ahora manual
// Verificar si se debe incluir, o por ahora se excluye del sistema
