import axios from 'axios';

export const httpClient = axios.create({
    withCredentials: false,
    credentials: 'same-origin',
    headers: {
        Accept: 'application/json',
        baseURL: process.env.VUE_APP_API_BASE,
        'Content-Type': 'application/json'
    }
});
