import { createStore } from 'vuex';
import { httpClient } from '../utilities/httpClient.js';
import router from '../router/index.js';
import VuexPersistence from 'vuex-persist';

import authStore from './modules/auth.js';
import usersStore from './modules/users.js';
import opportunitiesStore from './modules/opportunities.js';
import bidsStore from './modules/bids.js';
import pagesStore from './modules/pages.js';
import dataStore from './modules/data.js';
import announcementsStore from './modules/announcements.js';
import organizationsStore from './modules/organizations.js';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: state => ({ auth: state.auth, users: state.users }),
});

export default createStore({
  state() {
    return {
      // Strings
      BASE_URL: process.env.VUE_APP_BASE_URL ? process.env.VUE_APP_BASE_URL : 'https://api.sebconnect.com',
      DEBUG: process.env.VUE_APP_DEBUG === 'true',
      //http://mccseb.azurewebsites.net
      //https://api.sebconnect.com
      // Current app state, nav state
      appVersion: require('../../package.json').version,
      definitions:{},
      sebNavCurrent: 'users',
      navInProgress: false,
      loadingOverlayOn: false,
      showBreadcrumb: false,
      showBackButton:false,
      currentOpportunity:'',
      currentBid:'',
      currentAnnouncement:'',
      currentNotification:'',
      currentPage:'',
      lockScroll: false,
    }
  },
  getters: {
    getDefinitions(state) {return state.definitions},
    getSebNavCurrent(state) {return state.sebNavCurrent},
    getLoadingOverlayOn(state) { return state.loadingOverlayOn},
    getShowBreadcrumbs(state) { return state.showBreadcrumbs},
    getCurrentOpportunity(state) { return state.currentOpportunity},
    getShowBackButton(state) { return state.showBackButton },
    getLockScroll(state) { return state.lockScroll },
    isDebug(state) { return state.DEBUG },
  },
  mutations: {
    setDefinitions(state, definitions) {state.definitions = definitions},
    setSebNavCurrent(state, sebNavCurrent) {
      console.log('CHANGE: ' + sebNavCurrent);
      state.sebNavCurrent = sebNavCurrent;
    },
    setNavInProgress(state, navInProgress) { state.navInProgress = navInProgress },
    setLoadingOverlayOn(state, loadingOverlayOn) { state.loadingOverlayOn = loadingOverlayOn},
    setShowBreadcrumbs(state, showBreadcrumbs) { state.showBreadcrumbs = showBreadcrumbs},
    setShowBackButton(state, showBackButton) { state.showBackButton = showBackButton },
    setLockScroll(state, lockScroll) { 
      state.lockScroll = lockScroll;
      var body = document.body;
      if(state.lockScroll) {
        body.classList.contains('lock-scroll') ? null : body.classList.add('lock-scroll');
      } else {
        body.classList.remove('lock-scroll');
      }
    }
  },
  actions: {
    goBack(){
        router.go(-1);
    },
    handleError(context, error){
        console.log(error.response);

        switch (error.response.status) {
            case 400:
                // Server error or unidentified error
                alert('ERROR: 400 - Server error');
                break;
            case 401:
                // Session token error
                alert('ERROR: 401 - Invalid Token');
                context.dispatch('handleSessionEnd');
                // router.replace('/login');
                break;
            case 403:
                // Forbidden
                alert('ERROR: 403 - Forbidden');
                break;
            case 404:
                // Not found error
                alert('ERROR: 404 - Not Found');
                break;
            case 409:
                // Process error - use custom messages for these
                alert('Error: ' + error.response.data.data);
                break;
            default:
                // ALL other cases
                alert('Error: undefined');
        }
    },
    getSystemDefinitions(context){
      return new Promise((resolve, reject) => {
        httpClient.get(context.rootState.BASE_URL + '/definitions', { 
        })
        .then((response) => {
            console.log(response);
            context.commit('setDefinitions', response.data.data);
            resolve();
        })
        .catch((error) => {
            context.dispatch('handleError', error);
            console.log(error.response);
            reject();
        });
      });// PROMISE
    },
    setCurrentOpportunity(context, id){
      context.state.currentOpportunity = context.getters.getOpportunitiesList.find((x) => {
        return x.id == id;
      });
      if(context.state.currentOpportunity == null) {
        context.state.currentOpportunity = 'none';
        alert('Error - Opportunity not found');
      } else {
        console.log('Opportunity set to: ' + context.state.currentOpportunity.id, + ' ' + ((context.state.currentOpportunity.title.substring(0,60))) + ' ' + context.state.currentOpportunity.organization_id);
        /* this.$gtag.event("view_opp", {
          "opp_id" : context.state.currentOpportunity.id,
          "opp_title" : ((context.state.currentOpportunity.title.substring(0,60))),
          'opp_org' : context.state.currentOpportunity.organization_id
        }) */
      }
    },
    manageBackButton(context, path){
      if(
        path == '/users/new-user' ||
        path == '/users/single-user' ||
        path == '/opportunities/single-opportunity' ||
        path == '/opportunities/new-opportunity'
        ){
        context.commit('setShowBackButton', true);
      } else {
        context.commit('setShowBackButton', false);
      }
    },
    uploadDocument(context, payload){
      // PAYLOAD
      /*
      {
        parent_id,
        parent_type,
        doc_data: formData
      }
       */
      // GENERATE FormData ENTITY
      console.log('FILE SIZE: ' + payload.doc_data.file.size);
      if(payload.doc_data.file.size > 157286400) {
        alert('ERROR - File exceeds size limit. Must be smaller than 150 MB');
        return;
      }
      var formData = new FormData();
      formData.append('label', payload.doc_data.label);
      formData.append('file_name', payload.doc_data.file_name);
      formData.append('file', payload.doc_data.file);
      formData.append('description', payload.doc_data.description);
      // SET DOCUMENT PARENT
      if(payload.parent_type == 'opportunity') {
        formData.append('opportunity_id', payload.parent_id);
      } else if (payload.parent_type == 'announcement') {
        formData.append('announcement_id', payload.parent_id);
      } else if (payload.parent_type == 'bid') {
        formData.append('bid_id', payload.parent_id);
      } else {
        alert('ERROR - File parent is not defined');
        return;
      }
      // CONSUME API
      return new Promise((resolve, reject) => {
        context.commit('setLoadingOverlayOn', true);
        httpClient.post(context.rootState.BASE_URL + '/admin/document', 
        formData,
        { 
          headers: {
              // Send saved login token with code verification
              Authorization: `Bearer ${context.rootGetters.getAuthToken}`
          }
        })
        .then((response) => {
            console.log('File created successfully');
            console.log(response);
            context.commit('setLoadingOverlayOn', false);
            resolve(response);
        })
        .catch((error) => {
            context.dispatch('handleError', error);
            console.log(error.response);
            context.commit('setLoadingOverlayOn', false);
            reject();
        }); 
      });// PROMISE
    },
    editDocumentData(){},
    deleteDocument(context, id){
      return new Promise((resolve, reject) => {
        context.commit('setLoadingOverlayOn', true);
        httpClient.delete(context.rootState.BASE_URL + '/admin/document/' + id,
        { 
          headers: {
              // Send saved login token with code verification
              Authorization: `Bearer ${context.rootGetters.getAuthToken}`
          }
        })
        .then((response) => {
            console.log('File deleted');
            console.log(response);
            // context.commit('setLoadingOverlayOn', false);
            resolve(response);
        })
        .catch((error) => {
            context.dispatch('handleError', error);
            console.log(error.response);
            context.commit('setLoadingOverlayOn', false);
            reject();
        }); 
      });// PROMISE
    },
  },
  modules: {
    auth: authStore,
    users: usersStore,
    opportunities: opportunitiesStore,
    bids: bidsStore,
    pages: pagesStore,
    data: dataStore,
    announcements: announcementsStore,
    organizations: organizationsStore,
  },
  plugins: [vuexLocal.plugin],
})
