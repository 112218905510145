import { httpClient } from '../../utilities/httpClient.js';
const announcementsStore = {
    state() {
        return {
            announcements: [],
            currentAnnouncement:'',
            currentAnnouncementDocs:[],
            announcementsHaveLoaded: false
        }
    },
    getters: {
        getAnnouncementsList(state) {return state.announcements},
        getCurrentAnnouncement(state) {return state.currentAnnouncement},
        getCurrentAnnouncementDocs(state) {return state.currentAnnouncementDocs},
        getAnnouncementsHaveLoaded(state) {return state.announcementsHaveLoaded},
    },
    actions: {
        getAnnouncements(context){
            // Construct the URL based on type of bid entity (user or opportunity)
            // entity: {type, id}
            var queryURL = context.rootState.BASE_URL + '/user/announcements?lower_date=2021-01-01';
            return new Promise((resolve, reject) => {
                context.commit('setAnnouncementsHaveLoaded', false);
                // context.commit('setLoadingOverlayOn', true);
                httpClient.get(queryURL, { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then((response) => {
                    console.log('Announcements loaded:');
                    console.log(response.data.data);
                    context.commit('setAnnouncementsHaveLoaded', true);
                    // context.commit('setLoadingOverlayOn', false);
                    context.commit('setAnnouncements', response.data.data);
                    resolve();
                })
                .catch((error) => {
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    // context.commit('setLoadingOverlayOn', false);
                    context.commit('setAnnouncementsHaveLoaded', false);
                    reject();
                }); 
            });// PROMISE
        },
        getAnnouncementDocs(context, id){
            return new Promise((resolve, reject) => {
                // context.commit('setLoadingOverlayOn', true);
                httpClient.get(context.rootState.BASE_URL + '/user/documents?announcement_id=' + id, { 
                    headers: {
                        // Send saved login token with code verification
                        Authorization: `Bearer ${context.rootGetters.getAuthToken}`
                    }
                })
                .then((response) => {
                    console.log('Got announcement docs:');
                    console.log(response);
                    // context.commit('setLoadingOverlayOn', false);
                    context.commit('setCurrentAnnouncementDocs', response.data.data);
                    resolve(response);
                })
                .catch((error) => {
                    context.dispatch('handleError', error);
                    console.log(error.response);
                    // context.commit('setLoadingOverlayOn', false);
                    reject();
                }); 
            });// PROMISE
        },
    },
    mutations: {
        setAnnouncements(state, announcements) { state.announcements = announcements },
        setCurrentAnnouncement(state, currentAnnouncement) { state.currentAnnouncement = currentAnnouncement },
        setCurrentAnnouncementDocs(state, currentAnnouncementDocs) { state.currentAnnouncementDocs = currentAnnouncementDocs },
        setAnnouncementsHaveLoaded(state, announcementsHaveLoaded) {state.announcementsHaveLoaded = announcementsHaveLoaded},
    }
};

export default announcementsStore;
