import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueGtag from "vue-gtag"
import store from './store'

createApp(App).use(store).use(router).use(VueGtag, {
    appName: 'SEBvendor',
    pageTrackerScreenviewEnabled: true,
    config: { 
      id: "G-RCH3J8CDJK",
      user_id : "&&& DB id of the logged in user",
    },
  }, router).mount('#app')
